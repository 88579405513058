import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import JobPosition from "./JobPosition";
import JobFilter from "./JobFilter";
import JobFilteredList from "./JobFilteredList";
import '../styles/positions.css';

class Positions extends Component {
  static findKey(key) {
    const obj = {
      'Role Type': 'job_role_type',
      'Title': 'title',
      'Technology': 'primary_skills',
      'Location': 'job_location',
    };
    return obj[key];
  }

  static compareObjects(objArray, obj) {
    return objArray.some(key => key.id === obj.id);
  }

  constructor(props) {
    super(props);
    // this.testimonialPrimaryData = this.props.input.primary;
    // this.spinnerDisplay = "block"
    this.selectFilter = this.selectFilter.bind(this);
    this.unSelectFilter = this.unSelectFilter.bind(this);
    this.updateSelectionCriteria = this.updateSelectionCriteria.bind(this);
    this.showJobListContainer = this.showJobListContainer.bind(this);
    this.closeAllFilterSections = this.closeAllFilterSections.bind(this);
    this.setJobData = this.setJobData.bind(this);
    this.showHideClearFilter = this.showHideClearFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.handleCloseBtnClick = this.handleCloseBtnClick.bind(this);
    this.clearAllBtnForMobile = this.clearAllBtnForMobile.bind(this);
    this.handleApplyFilterBtn = this.handleApplyFilterBtn.bind(this);
    this.handleJobListSorting = this.handleJobListSorting.bind(this);
    this.sortData = this.sortData.bind(this);

    this.filterSelection = [];
    this.jobFilters = [];
    this.jobFilterList = [];

    this.state = {
      data: [],
      spinnerDisplay: 'block',
      contentDisplay: 'block',
      dataUI: [],
      filteredItems: [],
      str: '',
      currentPage: 0,
      pageSize: 10,
      collapse: true,
      showclearAllButon: false,
      isDescendingSort: true,
    };
  }

  componentDidMount() {
    let jobFilterFlag = localStorage.getItem('jobFilterFlag');
    if (jobFilterFlag && jobFilterFlag === 'true') {
      this.filterSelection = JSON.parse(localStorage.getItem('jobFilterSelection')) || [];
    }
    localStorage.setItem("jobFilterFlag", "false");
    this.showHideClearFilter();
    let data = this.generateJobList(this.props.jobListData);

    data = this.sortData(data, true);
    this.jobFilterList = this.setJobData(data);
    this.setState({ data }, () => {
      if (this.filterSelection && this.filterSelection.length > 0) {
        this.updateSelectionCriteria();
      } else {
        this.setState({ dataUI: data });
        this.setState({ filteredItems: data });
      }
    });

    this.handleWindowSizeChange();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidUpdate() {
    const arrow = document.getElementById('sort-arrow');
    if (arrow) {
      arrow?.classList.remove('fa-arrow-down');
      arrow?.classList.remove('fa-arrow-up');
      this.state.isDescendingSort ? arrow?.classList.add("fa-arrow-down") : arrow?.classList.add("fa-arrow-up");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  generateJobList(jobList) {
    let jobDataList = [];
    if (jobList && jobList.length > 0) {
      this.jobTags = [];
      jobList.forEach((item, i) => {
        let itemNode = item.node ? item.node : item.document[0];
        if (itemNode.data.include_job_in_list !== false) {
          let pagePath = itemNode.data.page_path.text;
          const itemBody = itemNode.data.body;
          const jobObj = {};
          const indexForJobTag = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyJobTags";
          const indexForJobDescription = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyJobDescription";
          const indexForJobKeyDetails = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails";
          const indexForPrimarySkills = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyPrimarySkills";
          const jobTagIndex = itemBody.findIndex(indexForJobTag);
          const jobDescriptionIndex = itemBody.findIndex(indexForJobDescription);
          const jobKeyDetsilsIndex = itemBody.findIndex(indexForJobKeyDetails);
          const jobPrimarySkillsIndex = itemBody.findIndex(indexForPrimarySkills);
          jobObj.pagePath = pagePath;
          jobObj.displayName = itemNode.data.page_display_name.text;
          jobObj.href = `${itemNode.data.page_path.text}/${itemNode.data.page_display_name.text.split(" ").join("-")}`;
          if (jobDescriptionIndex > -1) {
            jobObj.title = itemBody[jobDescriptionIndex].primary.job_title;
            jobObj.description = itemBody[jobDescriptionIndex].primary.description;
          }
          if (jobPrimarySkillsIndex > -1) {
            let primary_skills = itemBody[jobPrimarySkillsIndex].items.map(item => item.technology);
            primary_skills = primary_skills.filter(function (el) {
              return el !== null || el !== "" || el !== 'undefined' || el !== " ";
            });
            primary_skills = primary_skills.join(',');
            jobObj.primary_skills = primary_skills;
          }
          if (jobKeyDetsilsIndex > -1) {
            jobObj.job_location = itemBody[jobKeyDetsilsIndex].primary.job_location;
            jobObj.experience_required = itemBody[jobKeyDetsilsIndex].primary.experience_required;
            jobObj.job_published_date = itemBody[jobKeyDetsilsIndex].primary.job_published_date;
            jobObj.job_role_type = itemBody[jobKeyDetsilsIndex].primary.job_role_type;
            jobObj.job_experience_field_name = itemBody[jobKeyDetsilsIndex].primary.job_experience_field_name.text;
            jobObj.location_field_name = itemBody[jobKeyDetsilsIndex].primary.location_field_name.text;
            jobObj.job_published_date_field_name = itemBody[jobKeyDetsilsIndex].primary.job_published_date_field_name.text;
            jobObj.role_type_field_name = itemBody[jobKeyDetsilsIndex].primary.role_type_field_name.text;
          }
          jobObj.id = itemNode.id;
          if (itemBody[jobTagIndex] && itemBody[jobTagIndex].items && itemBody[jobTagIndex].items.length > 0) {
            jobObj.jobTags = itemBody[jobTagIndex];
            jobObj.jobTags.items.sort((tagDataA, tagDataB) => {
              if (tagDataA.tag_keywords_list.toLowerCase() < tagDataB.tag_keywords_list.toLowerCase()) { return -1; }
              if (tagDataA.tag_keywords_list.toLowerCase() > tagDataB.tag_keywords_list.toLowerCase()) { return 1; }
              return 0;
            });
            itemBody[jobTagIndex].items.forEach(item => {
              const found = this.jobTags.some(el => el.tag_keywords_list === item.tag_keywords_list);
              if (!found) this.jobTags.push(item);
            });
          }
          let finalJobs = [];
          if (window.location.pathname === "/jobs-for-experienced/") {
            finalJobs = itemNode.data.for_freshers !== true ? jobDataList.push(jobObj) : [];
          } else {
            finalJobs = itemNode.data.for_freshers == true ? jobDataList.push(jobObj) : [];
          }


          return finalJobs;
        }
      });

      if (this.jobTags && this.jobTags.length > 0) {
        this.jobTags.sort((tagDataA, tagDataB) => {
          if (tagDataA.tag_keywords_list.toLowerCase() < tagDataB.tag_keywords_list.toLowerCase()) { return -1; }
          if (tagDataA.tag_keywords_list.toLowerCase() > tagDataB.tag_keywords_list.toLowerCase()) { return 1; }
          return 0;
        });
        this.jobTags = [{ tag_keywords_list: "All Topics" }, ...this.jobTags];
      }


      return jobDataList;
    }
  }
  updateDimensions() {
    if (window.innerWidth < 768) {
      this.setState({ collapse: true });
    } else {
      this.setState({ collapse: false });
    }
  }
  setJobData(jobList) {
    let jobFilterData = [
      {
        categoryName: 'Role Type',
        categoryType: 'multiple'
      },
      {
        categoryName: 'Location',
        categoryType: 'multiple'
      },
      {
        categoryName: 'Technology',
        categoryType: 'multiple'
      },
      {
        categoryName: 'Title',
        categoryType: 'multiple'
      }
    ];

    jobFilterData.map(category => {
      let categoryValueList = new Set();
      jobList.map(item => {
        const key = Positions.findKey(category.categoryName);
        let fitlerItem = item[key] && item[key].text ? item[key].text.trim(' ') : item[key];

        if ((category.categoryName === 'Location')) {
          let itemArray = [];
          if (fitlerItem) {
            if (fitlerItem.includes('USA')) {
              let location = fitlerItem.slice(0, fitlerItem.indexOf(","));
              categoryValueList.add(location);
            } else {
              itemArray = fitlerItem.split('/ ');
            }
          }
          if (itemArray) {
            itemArray.forEach(element => {
              if (element !== "/" && element !== " ") {
                categoryValueList.add(element.trim());
              }
            });
          }
        } else if (category.categoryName === 'Technology' || category.categoryName === 'Role Type') {
          let regEx = new RegExp(/,(?![^(]*\))/g);
          let itemArray = [];
          if (fitlerItem && fitlerItem.match(regEx)) {
            itemArray = fitlerItem.split(regEx);
          } else {
            if (fitlerItem) {
              itemArray[itemArray.length] = fitlerItem;
            }
          }
          if (itemArray && itemArray.length > 0) {
            itemArray.forEach(element => {
              let regEx = new RegExp(/\/(?![^(]*\))/g);
              if (element.match(regEx)) {
                let technologyArr = element.split(regEx);
                technologyArr.forEach(item => {
                  categoryValueList.add(item.trim());
                });
              } else {
                categoryValueList.add(element.trim());
              }
            });
          }
        } else {
          categoryValueList.add(fitlerItem);
        }
      });
      category.categoryValue = Array.from(categoryValueList).sort();
    });

    return jobFilterData;
  }

  closeAllFilterSections() {
    this.jobFilters.map((jobFilter) => {
      jobFilter.closeJobFilter();
    });
  }

  unSelectFilter = (value, key) => {
    const index = this.filterSelection.findIndex(x => x.value === value);
    if (index > -1) {
      this.filterSelection.splice(index, 1);
    }

    localStorage.setItem("jobFilterSelection", JSON.stringify(this.filterSelection));
    localStorage.setItem("jobFilterFlag", "true");
    this.updateSelectionCriteria();
    this.showHideClearFilter();
  };

  handleWindowSizeChange = () => {
    this.setState({ collapse: window.innerWidth <= 768 });
  };

  searchHandler = e => {
    const { value: toSearch } = e.target;
    const { dataUI: objects } = this.state;
    this.setState({ str: toSearch });
    const results = [];
    if (toSearch.length >= 3) {
      const keys = Object.keys(objects.length ? objects[0] : {});

      objects.forEach(obj => {
        keys.forEach(key => {
          if (
            key !== 'id' &&
            obj[key] !== null &&
            obj[key] !== undefined &&
            obj[key].toLowerCase().search(toSearch.toLowerCase()) >= 0
          ) {
            if (!Positions.compareObjects(results, obj)) results.push(obj);
          }
        });
      });
    }
    if (toSearch.length < 3) {
      const { filteredItems: filtered } = this.state;
      this.setState({ dataUI: filtered });
    } else {
      this.setState({ currentPage: 0 });
      this.setState({ dataUI: results });
    }
  };

  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }

  selectFilter(value, key, type, str) {
    if (value[value.length - 1] === ' ')
      value = value.substring(0, str.length - 2);
    const indexChec = this.filterSelection.findIndex(x => x.key === key);
    if (this.filterSelection.length && indexChec > -1 && type !== 'multiple') {
      this.filterSelection.splice(indexChec, 1);
      this.filterSelection.push({
        key,
        value,
      });
    } else {
      this.filterSelection.push({
        key,
        value,
      });
    }
    localStorage.setItem("jobFilterSelection", JSON.stringify(this.filterSelection));
    localStorage.setItem("jobFilterFlag", "true");
    this.updateSelectionCriteria();
    this.showHideClearFilter();
  }

  updateSelectionCriteria() {
    const filteredItems = this.filterSelection;
    const { data } = this.state;
    let selectedFilter = [];
    const filterBy = [];
    filteredItems.forEach(selection => {
      filterBy.push(selection.value);
    });

    filteredItems.forEach(selection => {
      if (selection.key === 'Location') {
        selectedFilter = data.filter(item => {
          let key = Positions.findKey(selection.key);
          //TODO: Remove this condition if the seperators are consistent
          let itemArray = [];
          if (item[key] !== null && item[key] !== undefined) {
            if (item[key].text.includes('USA')) {
              itemArray = item[key].text.split(',');
            } else {
              itemArray = item[key].text.split('/ ');
            }
          } else {
            itemArray = null;
          }
          if (itemArray && itemArray.length === 1) {
            return filterBy.includes(item[key].text);
          }
          if (itemArray) {
            for (let i = 0; i < itemArray.length; i += 1) {
              if (' ' === itemArray[i][itemArray[i].length - 1])
                itemArray[i] = itemArray[i].replace(' ', '');
              if (filterBy.includes(itemArray[i])) {
                return true;
              }
            }
          }
          return false;
        });
      } // end if
    });

    let furtherFilteredItems = (selectedFilter.length > 0) ? selectedFilter : data;
    let isTechnologyAvailable = false;
    filteredItems.forEach(selection => {
      if (selection.key !== 'Location') {
        const key = Positions.findKey(selection.key);
        isTechnologyAvailable = true;
        furtherFilteredItems = furtherFilteredItems.filter(item => {
          const itemArray = item[key] && item[key].text ? item[key].text : item[key] ? item[key] : null;
          if (itemArray && itemArray.length) {
            return filterBy.some(x => itemArray.indexOf(x) >= 0);
          }
          return false;
        });

      }
    });
    selectedFilter = isTechnologyAvailable ? furtherFilteredItems : selectedFilter;
    let newData = filteredItems.length > 0 ? selectedFilter : data;
    newData = this.sortData(newData, true);
    this.setState({ dataUI: newData });

    this.setState({ filteredItems: newData });
  }

  showJobListContainer() {
    this.setState({ collapse: !this.state.collapse });
  }

  showHideClearFilter() {
    if (this.filterSelection.length) {
      this.setState({ showclearAllButon: true });
    } else {
      this.setState({ showclearAllButon: false });
    }
  }

  clearAllFilters() {
    this.filterSelection = [];
    localStorage.setItem("jobFilterSelection", JSON.stringify(this.filterSelection));
    localStorage.setItem("jobFilterFlag", "true");
    this.updateSelectionCriteria();
    this.showHideClearFilter();
  }

  handleCloseBtnClick() {
    this.showJobListContainer();
  }

  clearAllBtnForMobile() {
    this.clearAllFilters();
    this.showJobListContainer();
  }

  handleApplyFilterBtn() {
    if (this.filterSelection && this.filterSelection.length > 0) {
      this.showJobListContainer();
    }
  }

  validateDate(dateString) {
    let DateArray;
    if (dateString && dateString.includes('-')) {
      DateArray = dateString.split('-');
      return new Date(DateArray[2], DateArray[1], DateArray[0]);
    } else {
      return new Date(dateString);
    }
  }

  sortData(jobList, isDescendingSort) {
    let sortedData = jobList.sort((a, b) => {
      var dateA = new Date(a.job_published_date);
      var dateB = new Date(b.job_published_date);
      if (isDescendingSort) {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });

    return sortedData;
  }

  handleJobListSorting() {
    let data = this.sortData(this.state.dataUI, !this.state.isDescendingSort);
    this.setState({ isDescendingSort: !this.state.isDescendingSort });
    this.setState({ dataUI: data });
    this.setState({ filteredItems: data });
  }

  render() {
    this.state.data.jobs = this.jobFilterList;
    const {
      contentDisplay: display,
      dataUI,
      data,
      str,
      spinnerDisplay,
      currentPage,
      pageSize,
      collapse,
      showclearAllButon
    } = this.state;
    const maxWidth = { display };
    const pagesCount = Math.ceil(dataUI.length / pageSize);
    const pages = Array.from(new Array(pagesCount), (val, index) => index + 1);
    return (
      <div className='main-positions-container'>
        {<Container className="slice-width-global mb-4" style={maxWidth}>
          <Row style={window.location.pathname === "/jobs-for-freshers/" ? { float: 'inline-end' } : {}}>
            <Col className="job-result-col" style={currentPage >= pagesCount - 1 ? { display: 'none' } : { display: 'flex' }}>
              {window.location.pathname === "/jobs-for-experienced/" ? <h3 className="job-result">{dataUI.length} Jobs</h3> : <></>}
              {window.location.pathname === "/jobs-for-experienced/" ? <div className="refine-filter-header visible-for-mobile">
                <button className="show-job-list" onClick={this.showJobListContainer}>
                  <i className="far fa-sliders-h" />
                </button>
              </div> : <></>}
              <div className="sort-continer visible-for-desktop" style={{ float: "inline-end" }}>
                Sort by:
                <div className={`most-recent-sort ${this.state.sortOption === "Most Recent" ? 'selected-sort' : ''}`} onClick={e => this.handleJobListSorting()}>
                  Most Recent
                  <i id="sort-arrow" className={this.state.isDescendingSort ? "fa fa-arrow-down" : "fa fa-arrow-up"} style={{ paddingLeft: '4px' }}></i>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {window.location.pathname === "/jobs-for-experienced/" ? <Col md={3} className="jobs job-filter-container pr-md-4">
              <div className={`visible-for-mobile`} style={{ display: showclearAllButon ? "block" : "none" }}>
                <Row noGutters>
                  <Col xs={9}>
                    <JobFilteredList input={this.filterSelection} actionRemove={this.unSelectFilter} />
                  </Col>
                  <Col xs={3} style={{ textAlign: 'end' }}>
                    <Button color="link clear-all-filter" onClick={this.clearAllFilters}>Clear All</Button>
                  </Col>
                </Row>
              </div>
              <div className={`visible-for-desktop`}>
                <Row style={{ display: showclearAllButon ? "block" : "none" }} noGutters>
                  <Col className="filtered-list-heading">
                    <div className="filter-Title refine-text">Filtered by: </div>
                    <Button color="link clear-all-filter" onClick={this.clearAllFilters}>Clear All</Button>
                  </Col>
                </Row>
                <Row style={{ display: showclearAllButon ? "block" : "none" }} noGutters>
                  <Col>
                    <JobFilteredList input={this.filterSelection} actionRemove={this.unSelectFilter} />
                  </Col>
                </Row>
              </div>
              <div className="visible-for-desktop">
                <div className="filter-container-mobile">
                  {data.jobs.map((item, i) => (
                    <JobFilter
                      ref={instance => this.jobFilters[i] = instance}
                      key={i}
                      input={item}
                      data={this.filterSelection}
                      actionAdd={this.selectFilter}
                      actionRemove={this.unSelectFilter}
                      closeAllFilterSections={this.closeAllFilterSections}
                    />
                  ))}
                </div>
                <div className="btn-container visible-for-mobile">
                  <button type="button" className="apply-filter-btn" onClick={this.handleApplyFilterBtn}>Apply Filters</button>
                  <Button color="link" className="mobile-clear-all-btn" onClick={this.clearAllBtnForMobile}> Clear all</Button>
                </div>
              </div>
            </Col> : <></>
            }
            <Col md={9} className="jobs job-listing-container">
              {dataUI.length > 0 ? <div className="positions-container">
                {dataUI.slice(0, (currentPage + 1) * pageSize).map(item => (
                  <JobPosition
                    key={item.id}
                    input={item}
                    pagesLinksData={this.props.pagesLinksData}
                  />
                ))}
                <div className="load-more-jobs" onClick={e => this.handleClick(e, currentPage + 1)} style={currentPage >= pagesCount - 1 ? { display: 'none' } : { display: 'flex' }}>
                  <div className="load-button-content">Load More Results</div>
                </div>
              </div> : <div className="container">
                <p className="no-card-msg">
                  We are sorry, There is no job currently.
                </p>
              </div>}
            </Col>
          </Row>
        </Container>
        }
        {
          window.location.pathname === "/jobs-for-experienced/" && <div className={this.state.collapse ? 'job-filter-container hide' : 'job-filter-container visible-filter-for-mobile'}>
            <div className="job-filter-mobile">
              <h3 className="refine-text-for-mobile visible-for-mobile">Filter By:</h3>
              <div className="close-icon visible-for-mobile" onClick={this.showJobListContainer}>
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="filter-container-mobile">
              {data.jobs.map((item, i) => (
                <JobFilter
                  ref={instance => this.jobFilters[i] = instance}
                  key={i}
                  input={item}
                  data={this.filterSelection}
                  actionAdd={this.selectFilter}
                  actionRemove={this.unSelectFilter}
                  closeAllFilterSections={this.closeAllFilterSections}
                />
              ))}
            </div>
            <div className="btn-container visible-for-mobile">
              <button type="button" className="apply-filter-btn" onClick={this.handleApplyFilterBtn}>Apply Filters</button>
              <Button color="link" className="mobile-clear-all-btn" onClick={this.clearAllBtnForMobile}> Clear all</Button>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default Positions;

Positions.propTypes = {
  input: PropTypes.object,
  //jobsList: PropTypes.array
};
