import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import '../styles/careerDescription.css';
import "../styles/profile.css";
import CarouselImages from "./../components/Carousel";

const SliceContainer = (props) => {
  console.log('SliceContainer', props);
  const carousalImages = props.items?.filter(item => {
    return !!item.carousel_image?.url;
  })
  const overlayImageClass = "";
  const value = ((props.primary?.description?.text === null || props.primary?.description?.text === undefined) && (props.primary?.link_text?.text === null || props.primary?.link_text?.text === undefined) && (props.primary?.description !== undefined)) ? "0px" : "44px";
  return <div className={`main-container-slice number_banner_container ${props.__typename === "PrismicPagePageContentCareerdescription" && props.primary.background_colour === "Dark Blue ~ [#0651DD]" && window.location.pathname === "/problems-we-solve/" ? "number_banner_container-pws-leadership-desc" : ""} ${props.slice_type === "careerdescription" && props.primary.background_colour === "Deep blue ~ [#0651DD]" && window.location.pathname === "/" ? "number_banner_container-join-global-main-container" : ""} `} style={{ backgroundColor: props.primary?.background_colour ? getColor(props.primary?.background_colour) : 'white', paddingBottom: value }} id={props.primary?.id}>
    <div className="container" style={{ color: '#313542' }}>
      <div className="row sub-container">
        {
          props.primary?.image_position === "Top" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4`}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        {(props.primary?.title?.text)&& (
          <div className={`col-12 mb-4 ${props.primary.background_colour === "Deep blue ~ [#0651DD]" ? "join-global-heading-block" : ""}`}>
            <div className="number-banner-title">
              <div className="heading-slice-container">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.title?.text }}></span>
              </div>
            </div>
          </div>
        )}
        {
          props.primary?.image_position === "Left" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4`}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        {props.primary?.testimonial_heading?.text && (
          <div className="col-12 mb-4">
            <div className="number-banner-title">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.testimonial_heading?.text }}></span>
            </div>
          </div>
        )}

        {props.primary?.social_media_header?.text && (
          <div className="col-12 mb-4">
            <div className="number-banner-title">
              <span dangerouslySetInnerHTML={{ __html: props.primary?.social_media_header?.text }}></span>
            </div>
          </div>
        )}
        {
          props.primary?.image_position === "Right" && window.location.pathname === "/problems-we-solve/" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4 ${window.location.pathname === "/problems-we-solve/" ? "PWS-image-right-container" : ""}`}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        {
          props.primary?.description?.text && props.primary?.image_position !== "Overlay" && <div className="col-12 mb-4 number-banner-description1" dangerouslySetInnerHTML={{ __html: props.primary?.description?.text }}>
          </div>
        }
        {
          props.primary?.image_position === "Right" && window.location.pathname === "/" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4 ${window.location.pathname === "/" ? "join-global-image-container" : ""} `}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        
        {
          props.primary?.image_position === "Overlay" && props.primary?.image?.url && <div className={`col-12 ${props.primary?.image_columns} align-items-center justify-content-center mb-4 Overlay-image-homepage`}>
            <img src={props.primary.image?.url}></img>
          </div>
        }
        {
          props.primary?.image_position === "Overlay" && props.primary?.description?.text && <div className="col-06 mb-4 number-banner-description number-banner-description-overlay" dangerouslySetInnerHTML={{ __html: props.primary?.description?.text }}>
          </div>
        }
        {
          props.primary?.image_position === "Bottom" && props.primary?.image?.url && <div className={`col-12 col-md-12 align-items-center justify-content-center`}>

            <img src={props.primary?.image?.url} style={{ maxHeight: 'fit-content', minWidth: '100%' }}></img>

          </div>
        }
      </div>
      {
        props.children && <div className={`row align-items-center description ${props.justifyCenter ? 'justify-content-center': ''}`}>
          {props.children}
        </div>
      }
    </div>
    {
      <div className="Jobs-Carousal-testimonial row align-items-center justify-content-center description">

        {
          carousalImages?.length > 0 ? <div className="col-12 align-items-center justify-content-center pb-3">

            <CarouselImages items={carousalImages} />
          </div> : <></>
        }

        {
          props.primary?.link_text?.text && <div className="col-12 align-items-center justify-content-center" style={{ display: "flex" }}>
            <a
              className="lead d-md-block"
              href={props.primary?.description_link?.url}
              hrefLang="en"
            >
              {props.primary?.link_text?.text && <Button color="primary" className="btn btn-primary" style={{ borderRadius: "0px" }}>{props.primary?.link_text?.text}</Button>}
            </a>
          </div>
        }
      </div>
    }
  </div>

}

export default SliceContainer;
