import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  AvForm,
  AvField,
  AvInput,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";
import uploadImage from "../../static/images/upload-solid.png";
import { getColor, camelize, isParamPresentInURL, getFormattedDate, checkCorporateEmail } from "../utils/universalHelperFunctions";
import "../styles/contact-form.css";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  Label,
  Container
} from "reactstrap";
require('formdata-polyfill');

class FormDisplaySection2 extends Component {
  constructor(props) {
    super(props);
    this.formDisplaySection2Data = this.props.input;
    console.log("props:::::",props);
    this.jobPosition = this.props.jobPosition;
    this.pageId = this.props.pageId;
    this.showInCareerJobsExtraSlice = this.props.showInCareerJobsExtraSlice;
    this.pageOverViewData = this.props.pageOverViewData;
    this.resourceOverviewData = this.props.resourceOverviewData;
    this.pageHeroData = this.props.pageHeroData;
    this.formCallPage = this.formDisplaySection2Data.primary.form_call_page;

    if (this.formDisplaySection2Data.primary.hasOwnProperty("api_url")) {
      this.formAPIUrl = this.formDisplaySection2Data.primary.api_url.text;
    } else {
      this.formAPIUrl = "#";
    }
    if (this.formDisplaySection2Data.primary.hasOwnProperty("node_api_url") && this.formDisplaySection2Data.primary.node_api_url.text) {
      this.formAPIUrl = this.formDisplaySection2Data.primary.node_api_url.text;
    }

    if (this.formDisplaySection2Data.primary.hasOwnProperty("file_name")) {
      this.fileName = this.formDisplaySection2Data.primary.file_name.text;
    } else {
      this.fileName = "File";
    }

    // If form submit valid entries, then handleValidSubmit function fires
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.createRequestObject = this.createRequestObject.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    // If form submit invalid entries, then handleInvalidSubmit function fires
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.clearTimeoutOfMessage = this.clearTimeoutOfMessage.bind(this);
    this.validateEmailField = this.validateEmailField.bind(this);
    this.createEmailDataObject = this.createEmailDataObject.bind(this);

    this.state = {
      data: {},
      contactFormFields: {},
      selectedFile: null,
      selectedResumeBase64: null,
      isCorporateEmail: (this.formCallPage === "Job Apply" || this.formCallPage === "Walkin") ? true : false,
      selectedFileName: null,
      thankYouMessage: "",
      hideForm: false,
      isFieldValid: true,
      serverError: "",
      emailValidationError: '',
      formId: '',
      domainName: '',
      showSpinner: false,
      hideSubmit: false,
      formLeftLocation: '57%',
      fromActiveCampaign: false,
      enableSubmitButton: false,
      formDisplaySection2Data: this.props.input,
      pageId: this.props.pageId,
      jobPosition: this.props.jobPosition,
      fieldsNotMandatory: null,
      isValidFile: true,
      isJobsPages: false,
      borderApplied: 'form-border-solid',
      isBorderApplied: false,
    };
    this.formField= {};
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {}; 
    if (props.pageId && props.pageId !== state.pageId) {
      return { pageId: props.pageId };
    }
    if (props.input && props.input !== state.formDisplaySection2Data) {
      newState.formDisplaySection2Data = props.input;
    }
    if (props.jobPosition && props.jobPosition !== state.jobPosition) {
      newState.jobPosition = props.jobPosition;
    }
    if(newState && Object.keys(newState).length > 0) {
      return newState;
    }

    return null;
  }

  isParamPresentInURL(name, url) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    return results ? true : false;
  }
  componentDidMount() {
    fetch("https://jsonip.com", {mode: 'cors'})
    .then(responseIPDetials => responseIPDetials.json())
    .then(IPDetails => fetch(`https://api.ipstack.com/${IPDetails.ip}?access_key=c04f9cf5cdbfa908261a073d0d6b3265`, {mode: 'cors'}))
    .then(responseCountryCode => responseCountryCode.json())
    .then(countryCode => this.setState({ cultureFlag: countryCode.country_code }));

    let url = window.location.href || '';
    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');
    let formId = "";
    if (pathName && pathName.length > 2) {
      formId = `${pathName[0].toLocaleLowerCase()}-${pathName[1].toLocaleLowerCase()}`;
    } else if (pathName && pathName.length <= 2 && pathName[0]) {
      formId = `${pathName[0].toLocaleLowerCase()}`;
    } else {
      formId = 'homepage';
    }
    if (pathName && pathName[0] === 'jobs' && pathName[1] === 'open-positions') {
      formId = 'jobs';
    }

    if(window.location.pathname === "/jobs-for-freshers/" || window.location.pathname === "/jobs-for-experienced/") {
      this.setState({ isJobsPages: true });
    } else {
      this.setState({ isJobsPages: false });
    }

    if(window.location.pathname.includes("jobs/open-positions")) {
      this.setState({ isBorderApplied: true });
    } else {
      this.setState({ isBorderApplied: false });
    }

    if (formId) {
      this.setState({ formId });
    }

    if(document.querySelector('.overview-description-container')) {
      let formLeftLocation = document.querySelector('.overview-description-container ').offsetWidth + document.querySelector('.overview-container').offsetLeft;
      this.setState({formLeftLocation: formLeftLocation});
    }

    if (this.formCallPage === "Case Study" && isParamPresentInURL('id', url)) {
      this.setState({ hideForm: true, fromActiveCampaign: false });
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      checkCorporateEmail(value)
      .then(response => {
        if ( response.valid ) {
          this.setState({
            hideForm: true,
            fromActiveCampaign: true
          });
        } else {
          this.setState({
            hideForm: false,
            fromActiveCampaign: false
          })
        }
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.thankYouMessage !== prevState.thankYouMessage && this.state.thankYouMessage !== "") {
      this.timeoutID = setTimeout(() => {
        this.setState({ thankYouMessage: "", hideSubmit: false });
        this.setState({ hideForm: false });
        this.setState({ isCorporateEmail: false });
        if (this.props.handleAction) {
          this.props.handleAction(false);
        }
      }, 10000);
    }
  }

  clearTimeoutOfMessage() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }

  getFormattedDate(value) {
    let inputDate = new Date(value);
    let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(inputDate);
    let year = inputDate.getFullYear();
    let date = inputDate.getDate();
    return `${month} ${date}, ${year}`;
  }

  generateKeyForEmailData(element) {
    let pos = element.indexOf("_");
    while (pos > -1) {
      element = element.slice(0, pos + 1) + element.charAt(pos + 1).toUpperCase() + element.slice(pos + 2);
      pos = element.indexOf('_', pos + 1);
    }
    element = element.replace(new RegExp('_', 'g'), '');

    return element;
  }

  createEmailDataObject(values) {
    let emailData = [];
    let workEmail = values.workEmail || values.companyEmailAddress;

    let emailTemplate;
    if(this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
      if(this.formDisplaySection2Data.primary.form_identifier) {
        this.props.emailTemplateData.forEach(template =>  {
          if(this.formDisplaySection2Data.primary.form_identifier && template.primary.form_identifier_for_teamplate === this.formDisplaySection2Data.primary.form_identifier) {
            emailTemplate = template.items;
          }
          if(this.formDisplaySection2Data.primary.form_identifier && template.primary.form_identifier_for_teamplate === this.formDisplaySection2Data.primary.form_identifier) {
            emailTemplate = template.items;
          }
        });
      } else {
        emailTemplate = this.props.emailTemplateData[0].items;
      }
    }
    if (emailTemplate) {
      emailTemplate.forEach(element => {
        if (this.formCallPage === "Case Study" && element.user_type === "User" && this.state.domainName !== "gspann.com") {
          return;
        }

        if (element.email_template_link && element.email_template_link.document.length > 0) {
          let emailObj = {};
          let emailTemp = element.email_template_link && element.email_template_link.document[0].data;
          let signature = emailTemp.signature && emailTemp.signature.raw.length > 1 ? emailTemp.signature.html : emailTemp.signature.raw[0].text;
          let content = emailTemp.email_content ? emailTemp.email_content.html ? emailTemp.email_content.html : emailTemp.email_content.raw[0].text : '';
          let salutation = emailTemp.salutation ? emailTemp.salutation.html ? emailTemp.salutation.html : emailTemp.salutation.raw[0].text : '';

          if (values) {
            for (let key in values) {
              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, values[key]);
              }
              if (salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, values[key]);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, values[key]);
              }
            }
          }

          if (this.resourceOverviewData) {
            for (let element in this.resourceOverviewData.primary) {
              let key = this.generateKeyForEmailData(element);
              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
              if (salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.resourceOverviewData.primary[element].text);
              }
            }
          }

          if (this.pageOverViewData) {
            for (let element in this.pageOverViewData.primary) {
              let key = this.generateKeyForEmailData(element);
              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.pageOverViewData.primary[element].text);
              }
            }
          }

          if (this.pageHeroData) {
            this.pageHeroData.items.forEach(item => {
              for (let element in item) {
                let key = this.generateKeyForEmailData(element);
                let expression = '\{' + key + '\}';
                let regEx = new RegExp(expression, 'gi');
                if (content.match(regEx) != null) {
                  content = content.replace(regEx, item[element].text);
                }
                if (salutation.match(regEx) != null) {
                  salutation = salutation.replace(regEx, item[element].text);
                }
                if (signature.match(regEx) != null) {
                  signature = signature.replace(regEx, item[element].text);
                }
              }
            });
          }

          if (element.description && element.description.html) {
            let descriptionList = element.description.html.split(new RegExp('<p>\\s*@+\\*+@+\\s*<\/p>', 'g'));
            if (descriptionList) {
              let descriptionObj = {}
              descriptionList.forEach(element => {
                let key = element.match(new RegExp('<p>.*(::)\s*<\/p>'));
                if (key) {
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').replace('::', '').trim();
                  descriptionObj[key] = element.split(new RegExp('<p>.*(::)\s*<\/p>'))[2];
                } else if (element.match(new RegExp('<p>.*(::).*<\/p>'))) {
                  key = element.match(new RegExp('<p>.*(::).*<\/p>'));
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').split('::');
                  if (key) {
                    descriptionObj[key[0]] = key[1];
                  }
                } else {
                  return;
                }
              });
              if (descriptionObj && Object.entries(descriptionObj).length > 0) {
                for (let key in descriptionObj) {
                  let expression = '\{' + key + '\}';
                  let regEx = new RegExp(expression, 'gi');
                  if (content.match(regEx) != null) {
                    content = content.replace(regEx, descriptionObj[key]);
                  }
                  if (salutation.match(regEx) != null) {
                    salutation = salutation.replace(regEx, descriptionObj[key]);
                  }
                  if (signature.match(regEx) != null) {
                    signature = signature.replace(regEx, descriptionObj[key]);
                  }
                }
              }
            }
          }

          if (this.formCallPage === "Events") {
            if (this.resourceOverviewData) {
              let startDate = getFormattedDate(this.resourceOverviewData.primary.event_start_timestamp);
              let endDate = getFormattedDate(this.resourceOverviewData.primary.event_end_timestamp);

              content = content.replace('{eventDate}', `${startDate} - ${endDate}`);
            }
          }

          if (this.formCallPage === "Case Study") {
            let id = `${workEmail}|fileDownload`;

            let encryptedID = window.btoa(id);
            let downloadUrl = this.state.formDisplaySection2Data.primary.file_content.url || this.state.fromActiveCampaign ? `${window.location.href}?id=${encryptedID}`: `${window.location.href}`;

            if (new RegExp('href=\"https:\/\/{url}\"', 'g').exec(content)) {
              content = content.replace(/href="https:\/\/{url}"/g, `href=${downloadUrl}`)
            }

            content = content.replace('{url}', downloadUrl);
          }

          if (emailTemp.subject.text) {
            emailObj.sub = emailTemp.subject.text;
          }

          if (this.state.domainName && this.state.domainName === "gspann.com") {
            let regExForRecipient = new RegExp('\{gspann-.*\}', 'gi');
            if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regExForRecipient)) {
              let matchArray = emailTemp.recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.cc_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.cc_recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.bcc_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.bcc_recipient.text = matchArray.join(',');
              }
            }
            if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regExForRecipient) != null) {
              let matchArray = emailTemp.from_recipient.text.match(regExForRecipient);
              if (matchArray && matchArray.length > 0) {
                emailTemp.from_recipient.text = matchArray.join(',');
              }
            }
          }
          if (emailTemp.body && emailTemp.body.length > 0) {
            emailTemp.body.forEach(element => {
              if (element.slice_type === 'recipient_list' && element.items && element.items.length) {
                element.items.forEach(element => {
                  let expression = `\{${element.team_name.text}\}`;
                  let regEx = new RegExp(expression, 'gi');

                  if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regEx) != null) {
                    emailTemp.recipient.text = emailTemp.recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regEx) != null) {
                    emailTemp.cc_recipient.text = emailTemp.cc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regEx) != null) {
                    emailTemp.bcc_recipient.text = emailTemp.bcc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regEx) != null) {
                    emailTemp.from_recipient.text = emailTemp.from_recipient.text.replace(regEx, element.recipients.text);
                  }
                });
              } else if (element.slice_type === 'default_key_value' && element.items && element.items.length) {
                element.items.forEach(element => {
                  if (element.key.text) {
                    let expression = '\{' + element.key.text + '\}';
                    let regEx = new RegExp(expression, 'gi');
                    let value = element.default_value.text || element.multiline_default_value.html;
                    if (value) {
                      if (content.match(regEx) != null) {
                        content = content.replace(regEx, value);
                      }
                      if (salutation.match(regEx) != null) {
                        salutation = salutation.replace(regEx, value);
                      }
                      if (signature.match(regEx) != null) {
                        signature = signature.replace(regEx, value);
                      }
                    }

                  }
                });
              }
            });
          }
          if (process.env.GATSBY_ACTIVE_ENV && process.env.GATSBY_ACTIVE_ENV === "staging") {
            if (element.user_type === "User") {
              emailObj.to = workEmail || values.emailAddress;
            } else{
              if(workEmail && this.state.domainName === "gspann.com") {
                emailObj.to = workEmail;
              } else if(values.emailAddress && values.emailAddress.split('@')[1] === 'gspann.com') {
                emailObj.to = values.emailAddress;
              } else {
                emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
              }
            }
          } else {
            if (element.user_type === "User") {
              if (this.formCallPage === "Case Study" && this.state.domainName === "gspann.com") {
                emailObj.to = workEmail || values.emailAddress || values.companyEmail;
              } else {
                emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : workEmail || values.emailAddress;
              }
            } else {
              emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
            }
          }

          salutation = !salutation ? "Hello Visitor" : salutation;

          if (emailTemp.cc_recipient && emailTemp.cc_recipient.text) {
            emailObj.cc = emailTemp.cc_recipient.text;
          }

          if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text) {
            emailObj.bcc = emailTemp.bcc_recipient.text;
          }

          if (emailTemp.from_recipient && emailTemp.from_recipient.text) {
            emailObj.from = emailTemp.from_recipient.text;
          }

          emailObj.emailTempStr = salutation.concat(content, signature);

          emailObj.userType = element.user_type;

          emailData[emailData.length] = emailObj;
        }
      });
    }
    return emailData;
  }

  createRequestObject(values) {
    let requestObject = new FormData();
    requestObject.append('sourceId', this.state.pageId);

    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');

    if (pathName.length > 2) {
      requestObject.append('categoryName', pathName[0].toUpperCase());
      requestObject.append('subCategoryName', pathName[1].toUpperCase());
    } else if (pathName.length == 2) {
      requestObject.append('categoryName', pathName[0].toUpperCase());
    } else if (pathName.length < 2) {
      requestObject.append('categoryName', this.props.pageId.toUpperCase());
    }
    if (requestObject.get('categoryName') === "CONTACT-US") {
      requestObject.set('categoryName', 'HOMEPAGE');
    }

    let formObj = {};

    formObj.event = this.state.formDisplaySection2Data.primary.event.text || "test";
    formObj.eventData = this.formDisplaySection2Data.primary.event_data.text || "test";

    for (let key in values) {
      if (["firstName", "lastName", "workEmail", "emailAddress", "companyEmailAddress", "companyEmail"].indexOf(key) > -1) {
        if (['companyEmailAddress', 'workEmail', 'emailAddress', 'companyEmail'].indexOf(key) > -1) {
          requestObject.append('email', values[key]);
        } else {
          requestObject.append(key, values[key]);
        }
      } else {
        if (key === 'checkbox') {
          formObj.signUpForEmails = values.checkbox;
        } else {
          formObj[key] = values[key];
        }
      }
    }

    if (requestObject.get('categoryName') === 'RESOURCES') {
      if (isParamPresentInURL('id', values.PageUrl) && this.state.formDisplaySection2Data.primary.hasOwnProperty('campaignid') && this.state.formDisplaySection2Data.primary.campaignid.text) {
        requestObject.append('campaignid', this.state.formDisplaySection2Data.primary.campaignid.text);
      }
      if(this.state.formDisplaySection2Data.primary.hasOwnProperty('zendesk_crm_tag_id') && this.state.formDisplaySection2Data.primary.zendesk_crm_tag_id && this.state.formDisplaySection2Data.primary.zendesk_crm_tag_id.text) {
        let tags = this.state.formDisplaySection2Data.primary.zendesk_crm_tag_id.text;
        formObj.tags = [tags];
      }
    } else if (requestObject.get('categoryName') === "JOBS") {
      requestObject.append('file', this.state.selectedFile);
      formObj.country = this.state.cultureFlag;
      formObj.fileName = this.state.selectedFileName;
      formObj.email = this.state.data.companyEmail;
      formObj.jobPosition = this.props.pageDisplayName;
      formObj.jobID = this.props.jobID;
      values.jobPosition = this.props.pageDisplayName;
      values.country = formObj.country;
      values.id = this.props.jobID;

      if (!requestObject.get('subCategoryName')) {
        requestObject.append('subCategoryName', "FIND-A-POSITION");
      }
    } else if (requestObject.get('categoryName') === "PROBLEMS-WE-SOLVE" || requestObject.get('categoryName') === "JOBS-FOR-EXPERIENCED" || requestObject.get('categoryName') === "LIFE-AT-GSPANN" || requestObject.get('categoryName') === "JOBS-FOR-FRESHERS" || requestObject.get('categoryName') === "CAREERHOMEPAGE" ) {
      requestObject.append('file', this.state.selectedFile);
      formObj.country = this.state.cultureFlag;
      formObj.fileName = this.state.selectedFileName;
      formObj.email = this.state.data.companyEmail;
      values.to = this.state.data.companyEmail;
      values.id = this.props.jobID === (null || undefined) ? "Careers Resume" : this.props.jobID;
      values.emailAddress= this.state.data.emailAddress ? this.state.data.emailAddress : this.state.data.companyEmail;
      if (!requestObject.get('subCategoryName')) {
        requestObject.append('subCategoryName', "FIND-A-POSITION");
      }
    }
    values.pageDisplayName = this.props.pageDisplayName ? this.props.pageDisplayName : "Careers Home Page";
    // if (this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
      requestObject.append('emailData', JSON.stringify(this.createEmailDataObject(values)));
    // }
    // requestObject.append('emailData', JSON.stringify(this.createEmailDataObject(values)));
    requestObject.append('formData', JSON.stringify([formObj]));
    return requestObject;
  }

  submitFormData(values) {
    if (this.formAPIUrl.indexOf(process.env.GATSBY_API_URL) < 0) {
      this.formAPIUrl = this.formAPIUrl.includes('/', 0) ? `${process.env.GATSBY_API_URL}${this.formAPIUrl}` : `${process.env.GATSBY_API_URL}/${this.formAPIUrl}`;
    }
    let successMsg = this.state.formDisplaySection2Data.primary.success_message.text;
    if (this.state.domainName === "gspann.com" && this.formCallPage === "Case Study") {
      if (this.state.formDisplaySection2Data.primary.hasOwnProperty('success_message_for_gspann_user') && this.state.formDisplaySection2Data.primary.success_message_for_gspann_user.text) {

        successMsg = this.state.formDisplaySection2Data.primary.success_message_for_gspann_user.text;
        successMsg = successMsg.replace(/mailbox/g, 'inbox');
      } else {

        successMsg = 'Thank you for requesting the document. Please check your inbox for the document download link.';
      }
    }

    let failureMsg = this.state.formDisplaySection2Data.primary.failure_message.text;
    let requestObject = this.createRequestObject(values);

    let config = {}

    if (requestObject.get('categoryName') === "JOBS") {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Cache-Control': 'no-cache'
        },
      }
    } else {
      config = {
        headers: {
          'Cache-Control': 'no-cache'
        },
      }
    }

    axios.post(this.formAPIUrl, requestObject, config)
      .then(response => {
        let checkboxList = Array.from(document.querySelectorAll("input[type='checkbox']"));
        if (checkboxList.length) {
          checkboxList.forEach(element => {
            element.checked = false;
          });
        }
        let fileList = Array.from(document.querySelectorAll(".custom-file-upload input[type='file']"));
        if (fileList.length) {
          fileList.forEach(element => {
            element.value = '';
          });
        }
        this.formRef.reset();
        this.setState({ showSpinner: false });
        this.setState({ selectedFile: null });
        if (!(this.formCallPage === "Case Study")) {
          this.setState({ hideForm: true });
        }
        this.setState({ thankYouMessage: successMsg });
      })
      .catch(err => {
        console.log(err.response);
        let checkboxList = document.querySelectorAll("input[type='checkbox']");
        if (checkboxList.length) {
          checkboxList.forEach(element => {
            element.checked = false;
          });
        }
        let fileList = document.querySelectorAll(".custom-file-upload input[type='file']");
        if (fileList.length) {
          fileList.forEach(element => {
            element.value = '';
          });
        }
        this.formRef.reset();
        this.setState({ selectedFile: null });
        this.setState({ showSpinner: false, hideSubmit: true });
        if (!(this.formCallPage === "Case Study")) {
          this.setState({ hideForm: true });
        }
        this.setState({
          serverError: failureMsg
        });
        setTimeout(() => {
          this.setState({
            serverError: "",
            selectedFile: null,
            hideSubmit: false,
          });
          this.formRef.reset();
        }, 5000);

      });
  }

  //creating connection with Contact Data API for valid form entries.
  handleValidSubmit(event, values) {
    Object.entries(values).forEach(([key, value]) => {
      if (!(value)) {
        delete values[key];
      }
    });
    this.setState({ showSpinner: true, hideSubmit: true });
    if (values.workEmail || values.companyEmailAddress) {
      let workEmail = values.workEmail || values.companyEmailAddress;
      checkCorporateEmail(workEmail)
      .then(response => {
        if ( response.valid ) {
          this.setState({
            isCorporateEmail: true,
            data: values,
            domainName: workEmail.substring(workEmail.lastIndexOf("@") +1)
          });
          if (this.pageOverViewData && this.pageOverViewData.primary.page_title && this.pageOverViewData.primary.page_title.text) {
            values.pageTitle = this.pageOverViewData.primary.page_title.text;
          } else {
            values.pageTitle = '';
          }
          values.PageUrl = window.location.href;
          this.submitFormData(values);

        } else {
          this.setState({
            isCorporateEmail: false,
            showSpinner: false,
            hideSubmit: false,
            emailValidationError: 'Please enter a valid corporate email.'
          })
        }
      })
      .catch(err => {
        this.setState({
          showSpinner: false,
          hideSubmit: true,
          emailValidationError: err
        });
        setTimeout(() => {
          this.setState({
            emailValidationError: "",
            hideSubmit: false
          });
          this.formRef.reset();
        }, 500);
      });
    } else {
      this.setState({
        data: values
      });

      if (this.pageOverViewData && this.pageOverViewData.primary.page_title && this.pageOverViewData.primary.page_title.text) {
        values.pageTitle = this.pageOverViewData.primary.page_title.text;
      } else {
        values.pageTitle = '';
      }
      values.PageUrl = window.location.href;
      this.submitFormData(values);
    }
  }

  fileChangedHandler1 = event => {
    const inputFile = event.target.files[0];
    this.setState({
      selectedFile: inputFile,
      selectedFileName: inputFile.name
    }, () => {
      console.log("file handler");
      this.handleInputChange();
    });
  };

  fileChangedHandler = event => {
    const regex = new RegExp('\\.(docx|doc|pdf)$', 'i');
    const inputFile = event.target.files[0];
    if (regex.test(inputFile.name)) {
      this.setState({
        selectedFile: inputFile,
        selectedFileName: inputFile.name,
        isValidFile: true
      }, () => {
        // console.log(inputFile,"deva file");
        console.log("file handler");
        this.handleInputChange();
      });
    } else {
      this.setState({ isValidFile: false });
    }
    // console.log(event,"5rose");
  };

  // showing error in filling invalid entries.
  handleInvalidSubmit(event, errors, values) {
    console.log("++++++ Invalid Submit" + errors, values);
  }

  validateEmailField(event) {
    if (this.state.emailValidationError !== '') {
      this.setState({ emailValidationError: '' });
    }
  }

  createFormField(fieldLabel, fieldType, isFieldRequired) {
    let isRequired = isFieldRequired === "false" ? false : true;
    let formFontColor = this.state.formDisplaySection2Data.primary.form_section_font_color ? getColor(this.state.formDisplaySection2Data.primary.form_section_font_color) : '#495057';
    let emailValidationError;
    if (this.state.emailValidationError) {
      emailValidationError = (
        <div className="invalid-feedback">{this.state.emailValidationError}</div>
      )
    }

    if (fieldLabel != null) {
      var keyForRequestData = camelize(fieldLabel);
    }

    switch (fieldType) {
      case "check box": {
        if (this.formCallPage === "Walkin") {
          return (this.formField = (
            <AvCheckbox label={fieldLabel} name={keyForRequestData} value={keyForRequestData} />
          ));
        } else {
          return (this.formField = (
            <Col key={fieldLabel} md={12}>
              <Label check>
                <AvInput onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
                  type="checkbox" name={keyForRequestData} style={{color: formFontColor}}/>
                {fieldLabel}
              </Label>
            </Col>
          ));
        }
      }

      case "Check box":
        return (this.formField = (
          <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 4} className="mx-auto">
            <Label check>
              <AvInput onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
                type="checkbox" name="checkbox" style={{color: formFontColor}}/>
              {fieldLabel}
            </Label>
          </Col>
        ));

      case "Multiline":
        return (this.formField = (
          <Col key={fieldLabel} md={12}>
            <AvField
              onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
              label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${isRequired ? '*' : ''}` : ''}`}
              type="textarea"
              name={keyForRequestData}
              id="messageText"
              placeholder={`${this.formCallPage === "Case Study" ? '' : `Message${isRequired ? '*' : ''}`}`}
              bsSize="lg"
              className="message-input"
              style={{color: formFontColor}}
              validate={{
                required: { value: isRequired, errorMessage: `Please enter message.` },
              }}
            />
          </Col>
        ));

      case "Text Box":
        if (keyForRequestData === "location(City)") {
          keyForRequestData = "currentLocation";
        }
        //to make the field mandatory based on the IT experience selection(fresher)
        if(keyForRequestData == "currentCompany") {
          isRequired = this.state.fieldsMandatory ? (this.state.fieldsMandatory === "No" ? false : true) : isRequired;
        }

        return (this.formField = (
          <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 6}>
            <AvField
              onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
              label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${isRequired ? '*' : ''}` : ''}`}
              type={fieldType}
              name={keyForRequestData}
              placeholder={`${this.formCallPage === "Case Study" ? '' : `${fieldLabel}${isRequired ? '*' : ''}`}`}
              className={`all-form-input ${this.state.isBorderApplied ? this.state.borderApplied : ""}`}
              style={{color: formFontColor}}
              validate={{
                required: { value: isRequired, errorMessage: `*Required` },
                pattern: { value: "^[A-Z a-z]*$", errorMessage: `Only letters are allowed in the ${fieldLabel}.` },
              }}
            />
          </Col>
        ));

      case "Email":
        if (keyForRequestData === "emailAddress" || "companyEmail") {
          return (this.formField = (
            <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 6}>
              <AvField
                onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
                label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${isRequired ? '*' : ''}` : ''}`}
                type="email"
                name={keyForRequestData}
                id={keyForRequestData}
                placeholder={`${this.formCallPage === "Case Study" ? '' : `${fieldLabel}${isRequired ? '*' : ''}`}`}
                className={`all-form-input ${this.state.errorClass} ${this.state.isBorderApplied ? this.state.borderApplied : ""}`}
                style={{color: formFontColor}}
                validate={{
                  required: { value: isRequired, errorMessage: `*Required` },
                  email: { value: true, errorMessage: `This is not a valid email format. Please enter a valid email.` },
                }}
              />
            </Col>
          ));
        } else {
          return (this.formField = (
            <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 6} className={`${this.formCallPage === "Case Study" ? 'center-align-field' : ''} ${this.state.emailValidationError ? "email-input-row" : ''}`}>
              <AvField
                onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
                label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${isRequired ? '*' : ''}` : ''}`}
                type="email"
                name={keyForRequestData}
                id="workEmail"
                placeholder={`${this.formCallPage === "Case Study" ? '' : `${fieldLabel}${isRequired ? '*' : ''}`}`}
                className={`all-form-input ${this.state.errorClass} ${this.state.emailValidationError ? 'email-input-field' : ''} ${this.state.isBorderApplied ? this.state.borderApplied : ""}`}
                style={{color: formFontColor}}
                validate={{
                  required: { value: isRequired, errorMessage: '*Required' },
                  email: { value: true, errorMessage: "This is not a valid email format. Please enter a valid corporate email." },
                }}
                onChange={this.validateEmailField}
              />
              {emailValidationError}
            </Col>
          ));
        }

      case "Number":
        return (this.formField = (
          <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 6}>
            <AvField
              onFocus={this.handleInputChange} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}
              label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${isRequired ? '*' : ''}` : ''}`}
              name={keyForRequestData}
              id="number"
              placeholder={`${this.formCallPage === "Case Study" ? '' : `${fieldLabel}${isRequired ? '*' : ''}`}`}
              className={`all-form-input ${this.state.isBorderApplied ? this.state.borderApplied : ""}`}
              style={{color: formFontColor}}
              validate={{
                required: { value: isRequired, errorMessage: '*Required' },
                pattern: { value: "^[0-9]*$", errorMessage: 'Only numbers are allowed in this field.' },
                minLength: { value: 10, errorMessage: 'Phone number must contains 10 characters.' },
                maxLength: { value: 15, errorMessage: 'Phone number must contains 15 characters.' }
              }}
            />
          </Col>
        ));
      case "File Upload":
        return (this.formField = (
          <Col key={fieldLabel} md={12}>
            {this.state.isJobsPages && <label className="custom_file_upload">
              <input
                type="file"
                onChange={this.fileChangedHandler}
                onClick={(event) => {
                  event.target.value = null
                }}
                accept=".pdf,.doc,.docx"
                required
              />

              <div className="Logo_up" >
                <img src={uploadImage}
                  alt="header image" className="Logo_img" />
                <div className="upload_resume">Upload Resume </div></div>
              {!this.state.isValidFile && <Row style={{ color: 'red', paddingTop: '6px' }} >Please upload PDF or DOCX or DOC file format only.</Row>}
              <div className="pdf_design pdf_design_1">PDF or DOCX up to 5MB</div>
            </label>}
            {!this.state.isJobsPages && <>
            <label className="custom-file-upload">
              <input
                type="file"
                onChange={this.fileChangedHandler1}
                accept=".pdf,.doc,.docx"
                required
              />
              Upload Resume {isRequired ? '*' : ''}
            </label>
            <div className="pdf_design">PDF or DOCX up to 5MB</div>
            {
              this.state.selectedFile && (
                <p>{this.state.selectedFile.name}</p>
              )
            }
          </>}
          </Col>
        ));


      case "Drop Down":

        // To Remove Salary and Notice period fields ( from US only)
        let dropDownValues = fieldLabel.split("|");
        if (dropDownValues[0] === 'Current Salary (Annual)')
          break;
        if (dropDownValues[0] === 'Notice Period (Days)')
          break;

        if (dropDownValues[0] === 'Current Salary (Annual)') {
          break;
        }
        let dropDownKey;
        //to make the field mandatory based of IT experience selction(Fresher)
        let fieldMandatory = isRequired;
        switch (dropDownValues[0]) {
          case "Current Salary (Annual)":
            dropDownKey = "currentSalary";
            fieldMandatory = this.state.fieldsMandatory ? (this.state.fieldsMandatory === "No" ? false : true) : isRequired;
            break;
          case "Notice Period (Days)":
            dropDownKey = "noticePeriod";
            fieldMandatory = this.state.fieldsMandatory ? (this.state.fieldsMandatory === "No" ? false : true) : isRequired;
            break;
          case "Experience":
            dropDownKey = "totalItExperience";
            fieldMandatory = isRequired;
            break;
          case "Relevant Experience":
            dropDownKey = "relevantExperience";
            fieldMandatory = this.state.fieldsMandatory ? (this.state.fieldsMandatory === "No" ? false : true) : isRequired;
            break;
        }

        return (this.formField = (
          <Col key={fieldLabel} md={this.formCallPage === "Case Study" ? 12 : 6}>
            <AvField
              onFocus={this.handleInputChange} onBlur={this.handleInputChange} onChange={this.handleInputChange}
              label={`${this.formCallPage === "Case Study" ? `${fieldLabel}${fieldMandatory ? '*' : ''}` : ''}`}
              type="select"
              name={dropDownKey}
              className="form-check-label"
              style={{color: formFontColor}}
              validate={{
                required: { value: fieldMandatory, errorMessage: `*Required` },
              }}
            >
              {dropDownValues.map((item, i) => {
                if(i === 0) {
                  item = `${item}${fieldMandatory ? '*' : ''}`;
                }
                return (
                <option key={i} disabled={!i ? true : false} value={!i ? "" : item} style={{color: formFontColor}}>{item}</option>
              )})
            }
            </AvField>
          </Col >
        ));
    }
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  // to disable submit button before form is valid
  handleInputChange = function(e){
    if(e && e.target && e.target.name === 'totalItExperience') {
      if(e.target.value === 'Fresher') {
        this.setState({fieldsMandatory: "No"});
      } else {
        this.setState({fieldsMandatory: "Yes"});
      }
    }
    let buttonFlag = ((this.formCallPage === "Job Apply" || this.formCallPage === "Walkin") && !this.state.selectedFile) ? false : true;
    this.setState({
      enableSubmitButton: buttonFlag && Object.keys(this.formRef.state.invalidInputs).length === 0
    })
  }

  render() {
    let formBackgroundColor = this.state.formDisplaySection2Data.primary.form_section_background_color ?  getColor(this.state.formDisplaySection2Data.primary.form_section_background_color) : '#f1f5f6';
    let formHeadingFontColor = this.state.formDisplaySection2Data.primary.form_section_heading_font_color ? getColor( this.state.formDisplaySection2Data.primary.form_section_heading_font_color) : '#000';
    let formSubheadingFontColor = this.state.formDisplaySection2Data.primary.form_section_subheading_font_color ? getColor(this.state.formDisplaySection2Data.primary.form_section_subheading_font_color) : '#7f8c8d';
    let formFontColor = this.state.formDisplaySection2Data.primary.form_section_font_color ? getColor(this.state.formDisplaySection2Data.primary.form_section_font_color) : '#495057';
    let formButtonBackgroundColor = this.state.formDisplaySection2Data.primary.form_section_button_background ? getColor(this.state.formDisplaySection2Data.primary.form_section_button_background) : '#F7A900';
    let formbuttonFontColor = this.state.formDisplaySection2Data.primary.form_section_button_font_color ? getColor(this.state.formDisplaySection2Data.primary.form_section_button_font_color) : '#fff';
    
    //to save the value of Download form Heading

    let downloadFormHeading;
    let url = (typeof window === 'undefined') ? '' : window.location.href;
    //to save the value of walkin role header
    let walkinRoleHeader;
    let walkinRoleSubHeader;

    //checking if resource form data contains walkin_role_header property
    // , if yes then setting the value in walkinRoleHeader
    if (
      this.state.formDisplaySection2Data.primary.hasOwnProperty("walkin_role_header")
    ) {
      walkinRoleHeader = this.state.formDisplaySection2Data.primary.walkin_role_header
        .text;
    }
    if (
      this.state.formDisplaySection2Data.primary.hasOwnProperty("walkin_role_sub_header")
    ) {
      walkinRoleSubHeader = this.state.formDisplaySection2Data.primary.walkin_role_sub_header
        .text;
    }
    if(this.formCallPage && (this.formCallPage === "Job Apply" || this.formCallPage === "Walkin") && this.state.hideForm) {
      walkinRoleHeader = 'Thank you for your application!'
      walkinRoleSubHeader = ""
    }
    //checking if resource form data contains download_form_description property
    // , if yes then setting the value in downloadFormDescription
    let downloadFormDescription;
    if (this.state.formDisplaySection2Data.primary.hasOwnProperty("download_form_description")) {
      downloadFormDescription = this.state.formDisplaySection2Data.primary.download_form_description.text;
    } else {
      downloadFormDescription = null;
    }

    let downloadFormDescriptionElement;
    if (downloadFormDescription && !this.state.fromActiveCampaign) {
      downloadFormDescriptionElement = (
        <h3 style={{
          color: formHeadingFontColor,
          fontWeight: `${this.formCallPage === "Case Study" ? '' : 'normal'}`,
          display: `${this.state.hideForm ? 'none' : 'inline-block'}`
        }}
          className="resource-form-description">
          {downloadFormDescription}
        </h3>
      )
    } else {
      downloadFormDescriptionElement = null;
    }

    //to save the value of submit Button Text
    let submitButtonText, downloadButtonText;

    let formContainsAllFields = [];
    let formField;
    let formField2;
    let formField3;

    this.state.formDisplaySection2Data.items.map((item, key) => {
      formField = this.createFormField(
        item.field_label.text,
        item.field_type.text,
        item.field_required
      );

      formField2 = this.createFormField(
        item.field_label_2.text,
        item.field_type_2.text,
        item.field_required_2
      );
      if(item.field_label_3 && item.field_type_3) {
        formField3 = this.createFormField(
          item.field_label_3.text,
          item.field_type_3.text,
          item.field_required_3
        );
      }
      if (this.formCallPage === "Walkin" && (item.field_type.text === "check box" || item.field_type_2.text === "check box" || item.field_type_3.text === "check box")) {

        let checkboxFormGroup = (
          <Col key="checkbox" md={12}>
            <AvCheckboxGroup inline name="jobPosition" validate={{ required: { value: true, errorMessage: 'Please select atleast one position.' } }} className="checbox-group">
              {formField}
              {formField2}
              {formField3}
            </AvCheckboxGroup>
          </Col>
        );
        formContainsAllFields.push(checkboxFormGroup);
      } else {
        if(formField) {
          formContainsAllFields.push(formField);
        }
        if(formField2) {
          formContainsAllFields.push(formField2);
        }
        if(formField3) {
          formContainsAllFields.push(formField3);
        }
      }
    });

    let submitButton, downloadBtn;

    let submitBtnClass = '';

    if (this.formCallPage === "Walkin" && this.state.pageId) {
      submitBtnClass = `${this.state.pageId.split(" ").join("-")}-tag-manager-btn`;
    } else {
      submitBtnClass = `${this.formCallPage.split(" ").join("-")}-tag-manager-btn`
    }

    if (this.formCallPage === "Case Study" && isParamPresentInURL('id', url) && this.state.fromActiveCampaign) {
      if (this.state.formDisplaySection2Data.primary.hasOwnProperty("campaign_form_button_text")) {
        downloadButtonText = this.state.formDisplaySection2Data.primary.campaign_form_button_text.text;
      } else {
        downloadButtonText = null;
      }

      let searchParams = url.match(new RegExp('[\?&]id=([^&#]*)'));
      if (searchParams && searchParams.length > 0) {
        let decryptedId = searchParams[1];
        if (this.isBase64(searchParams[1])) {
          decryptedId = window.atob(searchParams[1]);
        }

        if (this.state.formDisplaySection2Data.primary.hasOwnProperty("campaign_form_heading") && decryptedId.indexOf('|fileDownload') > 0) {
          downloadFormHeading = this.state.formDisplaySection2Data.primary.campaign_form_heading.text;
        } else {
          downloadFormHeading = this.state.formDisplaySection2Data.primary.download_form_heading.text;
        }
      }

      downloadBtn = (
        <a href={this.state.formDisplaySection2Data.primary.file_content.url} className="download-link submit-btn" target="_blank" hrefLang="en" style={{color: formbuttonFontColor, background: formButtonBackgroundColor, borderColor: formButtonBackgroundColor}}>
          {downloadButtonText}
        </a>
      )
    } else {
      // checking if resource form data contains submit_button_text property
      // , if yes then setting the value in submitButtonText
      if (this.state.formDisplaySection2Data.primary.hasOwnProperty("submit_button_text")) {
        submitButtonText = this.state.formDisplaySection2Data.primary.submit_button_text.text;
      } else {
        submitButtonText = null;
      }

      //checking if resource form data contains download_form_heading property
      // , if yes then setting the value in downloadFormHeading
      if (this.state.formDisplaySection2Data.primary.hasOwnProperty("download_form_heading")) {
        downloadFormHeading = this.state.formDisplaySection2Data.primary.download_form_heading.text;
      } else {
        downloadFormHeading = null;
      }

      submitButton = (
        <div className={`${this.state.showSpinner ? "overlay-container" : ""} `}>
          <Button
            color="primary"
            className={`submit-btn ${submitBtnClass} form-disply-section-submit-btn`}
            style={{
              display: `${this.state.hideSubmit ? 'none' : 'inline-block'}`,
              color: formbuttonFontColor,
              background: formButtonBackgroundColor,
              borderColor: formButtonBackgroundColor,
            }}
            disabled={this.state.enableSubmitButton === false}
          >
            {submitButtonText}
          </Button>
          <div
            className="detail-page-loading"
            onClick={e => e.preventDefault()}
            style={{ display: this.state.showSpinner ? 'flex' : 'none' }}
          >
            <div className="spinner" />
          </div>
        </div>
      );
    }

    let serverErrorContainer = "";
    if (this.state.serverError) {
      serverErrorContainer = (
        <div className="invalid-server-error">{this.state.serverError}</div>
      );
    }

    let msg = "";
    if (this.state.thankYouMessage) {
      msg = (<div className={`msg-container ${this.formCallPage === "Case Study" ? 'resource-msg-container' : 'form-msg-container'}`}>{this.state.thankYouMessage}</div>);
    }

    let id = "";
    if (this.state.formDisplaySection2Data.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.state.formDisplaySection2Data.primary.breadcrumb_id.text;
    }

    let containerClassName = ` ${this.state.formId}-container`;
    if (!this.state.fromActiveCampaign) {
      let isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true: false;
      let allowedResourceType = this.props.resourceType  && (this.props.resourceType === "White Paper" || this.props.resourceType === "Case Study");
      //let allowedResourceType = this.props.resourceType  && this.props.resourceType === "White Paper";
      if(isNewDesign) {
        allowedResourceType = this.props.resourceType  && (allowedResourceType || this.props.resourceType === "Case Study");
      }
      //reverting new case study design (this.props.resourceType === "Case Study")//remove
      if (allowedResourceType && !this.state.hideForm) {
        containerClassName += 'slice-container resource-form-container';
      } else {
        containerClassName += ' slice-container';
      }
    } else {
      containerClassName += " container activeCampaign-container"
    }

    return (
      <div
        className={containerClassName}
        id={id}
        style={{ background: formBackgroundColor }}
      >
        {this.props.showInCareerJobsExtraSlice}
        <h2 style={{ fontWeight: "normal", fontSize: ".9rem", color: formSubheadingFontColor }}>
          {downloadFormHeading}
        </h2>
        <h3 className="walkin_role_header_class" style={{ color: formHeadingFontColor, fontWeight: "normal" }}>
          {walkinRoleHeader}
        </h3>
        <h3 className="walkin_role_sub_header_class" style={{ color: formHeadingFontColor, fontWeight: "normal" }}>
          {walkinRoleSubHeader}
        </h3>
        {downloadFormDescriptionElement}
        <Container style={{overflow: 'auto'}}>
          <AvForm
            ref={el => this.formRef = el}
            className={`form-container ${this.state.hideForm ? 'hide-form' : 'show-form'}`}
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <Row form> {formContainsAllFields}</Row>
            {serverErrorContainer}
            {submitButton}
          </AvForm>
          {downloadBtn}
        </Container>
        <div className="form-container msg-wrapper">
          {msg}
        </div>
      </div>
    );
  }
}

export default FormDisplaySection2;

FormDisplaySection2.propTypes = {
  input: PropTypes.object
};
