import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/ListWithIcons.css";

class ListWithIcons extends Component {
  constructor(props) {
    super(props);
    this.listWithIconsPrimaryData = this.props.input.primary;
    this.listWithIconsItems = this.props.input.items;
    this.state = {};
  }

  render() {
    const items = this.props.input?.items;

    return (
     <div className="row main-container-list-with-icons" style={{ backgroundColor: "#313542" }}>
      {/* This is for desktop view */}
        <div className="container list-main-container visible_for_desktop">
            <h1 className="heading">
              <span dangerouslySetInnerHTML={{ __html: this.listWithIconsPrimaryData.heading?.text }}></span>
            </h1>
            <div className="items-block" >
                {items.map((ele, index) => (
                <div key={index}  className="flex box items-center gap-2 sub-container-items">
                    {/* <img src={ele.icon.url}></img> */}
                    {ele.list_icon_code.text && <span dangerouslySetInnerHTML={{ __html:ele.list_icon_code.text }}></span>}
                    <p className="list-item-text">{ele.list_item.text}</p>
                </div>
                ))}
            </div>
        </div>
        {/* This is for mobile view */}
        <div className="container list-main-container visible_for_mobile">
            <h1 className="heading">
              <span dangerouslySetInnerHTML={{ __html: this.listWithIconsPrimaryData.heading?.text }}></span>
            </h1>
            <div className="items-block" >
                {items.map((ele, index) => (
                <div key={index}  className="flex box items-center gap-2 sub-container-items">
                    {/* <img src={ele.icon.url}></img> */}
                    {ele.list_icon_code_mobile.text && <span dangerouslySetInnerHTML={{ __html:ele.list_icon_code_mobile.text }}></span>}
                    <p className="list-item-text">{ele.list_item_mobile.text}</p>
                </div>
                ))}
            </div>
        </div>

     </div>

    );
  }
}
export default ListWithIcons;

ListWithIcons.propTypes = {
  input: PropTypes.object
};
