import React, { useState, useEffect } from "react";
import SliceContainer from "../components/SliceContainer";
import "../styles/profile.css";
import "../styles/image-magnifier-component.css";
import {
    Col,
    Row,
    Button,
    Label,
    Container,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const DescriptionWithProfiles = (props) => {
    const data = props.input?.primary;
    const items = props.input?.items;
    const [ReadStoryImage, setReadStoryImage] = useState("");
    const [ReadStorySetImage, setReadStorySetImage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dotValue, setDotValue] = useState(0);


    const HandleReadStory = (e,profileImage,idx,item) => {
        let setImagesArray = [];
        setReadStoryImage("");
        setReadStorySetImage([]);
        if (profileImage !== null && item.profile_description_popup_image_1.url === null){
            setReadStoryImage(profileImage);
        } else if(profileImage === null){
            setImagesArray.push(item.profile_description_popup_image_1.url,item.profile_description_popup_image_2.url,item.profile_description_popup_image_3.url,item.profile_description_popup_image_4.url);
            setReadStorySetImage(setImagesArray);
        }
        setDotValue(idx);
        setShowModal(true);
    };

    const onChange = (value) => {
        setDotValue(value);
    };

    const onChangeSlide = (value) => {
        if (value) {
            setDotValue(value);
        }
    };

    const toggle = () => {
        setShowModal(showModal => !showModal);
        setDotValue(dotValue);
    };
    const sliceSlides = [];

    items.map((item, index) => {
        let slideItem = (
            <div key={index} className={`align-items-center justify-content-center pt-4 mobile-view`}>
                <div className="row align-items-center justify-content-center">
                    <div key={index} className={`col-md-12  align-items-center justify-content-center mb-3`} style={{display:'flex'}}>
                        <img className="profile-image" src={item.profile_image.url}></img>
                    </div>
                    <div className="Profile-details">
                    <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                    <span className="profile-name">{item.designation.text}</span>
                    </div>
                    <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                        <span className="profile-position">{item.name.text}</span>
                    </div>
                    <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                        <a className="profile-link" onClick={e => HandleReadStory(e, item.profile_description_popup_image.url,index,item)}>Read Story <i class="fa-thin fa-arrow-right profiles-arrow"></i></a>
                    </div>
                    </div>
                </div>
            </div>)
        sliceSlides.push(slideItem);
    })

    return <SliceContainer {...props.input}>
        {
            items.map((item, index) => {
                return <div key={index} className={`col-6 col-md-3  align-items-center justify-content-center pt-4 desktop-view`}>
                    <div className="row align-items-center justify-content-center">
                        <div key={index} className={`col-md-12  align-items-center justify-content-center mb-3`} style={{display:'flex'}}>
                            <img className="profile-image" src={item.profile_image.url}></img>
                        </div>
                        <div className="Profile-details">
                        <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                        <span className="profile-name">{item.designation.text}</span>
                        </div>
                        <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                            <span className="profile-position">{item.name.text}</span>
                        </div>
                        <div key={index} className={`col-md-12  align-items-center`} style={{display:'flex'}}>
                            <a className="profile-link" onClick={e => HandleReadStory(e, item.profile_description_popup_image.url,index,item)}>Read Story <i class="fa-thin fa-arrow-right profiles-arrow"></i></a>
                        </div>
                        </div>
                    </div>
                </div>
            })
        }
            <div className="mobile-view">
            <Carousel
                value={dotValue}
                slides={sliceSlides}
                onChange={onChangeSlide}
                number={sliceSlides.length}
            />
            <Dots value={dotValue} onChange={onChange} number={sliceSlides.length} style={{margin: "auto"}} />
            </div>
        
        <Container id="ProfileDescriptionPopup">
            <Modal
            className="imageMagnifierModal container"
            isOpen={showModal}
            style={{top:"10%"}}
            >
            <ModalHeader className="desc_profile_modal_header" toggle={toggle}>
                <div className="popup-title">
                <span dangerouslySetInnerHTML={{ __html: data.title?.text }}></span>
                </div>
            </ModalHeader>
            <ModalBody className="modal-body-container desc_profile_modal_body">
            {ReadStoryImage.length >0 && <Col className='desc_profile_popup_image'>
                <img className="popup-single-image" alt="About Me" src={ReadStoryImage}></img>
            </Col>}
            {ReadStorySetImage.length >0 && <Col className="popup-set-images-main-container-main">
            <div className="popup-set-images-main-container">
            <img className="popup-set-image" src={ReadStorySetImage[0]}></img>
            <img className="popup-set-image" src={ReadStorySetImage[1]}></img>
            <img className="popup-set-image" src={ReadStorySetImage[2]}></img>
            <img className="popup-set-image" src={ReadStorySetImage[3]}></img>
            </div>
            </Col>}
            </ModalBody>
            </Modal>
        </Container>
    </SliceContainer>
}

export default DescriptionWithProfiles;