import React from "react"
import SliceContainer from "../components/SliceContainer";
import ImageCollage from "../components/ImageCollage";
import { createUrl } from "../utils/universalHelperFunctions";

const CareerDescription = (props) => {

    const data = props.input?.primary;
    const items = props.input?.items;
    return <SliceContainer {...props.input}>
        {
            items?.map((item, index) => {
                return (item.repetable_text.text) && <div key={index} className={`${item.columns}  align-items-center justify-content-center mb-4`}>
                    {
                        item.repetable_text.text
                    }
                </div>
            })
        }
    </SliceContainer>
};

export default CareerDescription;